<template>
  <v-row class="props-list">
    <v-col md="2">Имя</v-col>
    <v-col md="10">
      <v-text-field :value="expert.firstName" label="Имя" @input="setField('firstName', $event)" />
    </v-col>

    <v-col md="2">Фамилия</v-col>
    <v-col md="10">
      <v-text-field :value="expert.lastName" label="Фамилия" @input="setField('lastName', $event)" />
    </v-col>

    <v-col class="font-weight-regular" md="12">
      <div class="d-inline-flex">
        <v-checkbox v-model="isWorkplaceChangeProxy" class="my-0" label="Смена места работы" hide-details />
      </div>
    </v-col>

    <v-col md="2"> Инвест-дом </v-col>
    <v-col md="10">
      <div class="d-inline-flex">
        <select-invest-companies :expert="expert" :clearable="false" @change="changeCompany" />
      </div>
    </v-col>

    <v-col md="2">Количество подписчиков</v-col>
    <v-col md="10">
      <v-text-field
        :value="expert.followersCount"
        :rules="[value => validateFollowersCount(value) || 'Недопустимое значение']"
        dense
        hide-details
        @change="setField('followersCount', $event)"
      />
    </v-col>

    <v-col class="font-weight-regular" md="12">
      <v-checkbox v-model="isActiveProxy" class="my-0" label="Активный" hide-details />
    </v-col>

    <v-col class="font-weight-regular" md="12">
      <v-checkbox v-model="isForRuStocksProxy" class="my-0" label="Принадлежность (по акциям РФ)" hide-details />
    </v-col>
  </v-row>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

import SelectInvestCompanies from "~/components/experts/select-invest-companies.vue";

export default {
  mixins: [editorFields],
  props: {
    expert: {
      type: Object,
      required: true
    }
  },
  computed: {
    isForRuStocksProxy: {
      get() {
        return this.expert.forRuStocks;
      },
      set(value) {
        this.setField("forRuStocks", value);
      }
    },
    isWorkplaceChangeProxy: {
      get() {
        return this.expert.workplaceChange;
      },
      set(value) {
        this.setField("workplaceChange", value);
      }
    },
    isActiveProxy: {
      get() {
        return this.expert.isActive;
      },
      set(value) {
        this.setField("isActive", value);
      }
    }
  },
  methods: {
    validateFollowersCount(value) {
      return /^[0-9]+$/.test(value);
    },
    changeCompany(value) {
      this.setField("firmId", value);
    }
  },
  components: {
    SelectInvestCompanies
  }
};
</script>
