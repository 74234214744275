<template>
  <div class="pa-3">
    <expert-overview :expert="expertDto" @change-field="changeField" />

    <v-divider class="my-3" />

    <expert-media v-model="photo" :expert="expertDto" />

    <v-divider class="my-3" />

    <div class="text-right">
      <v-btn color="accent" :loading="updating" @click="handleSave">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
import { editorForm } from "~/mixins/multi-editor/editor-form";
import { encodeFileToBase64 } from "~/core/helpers";

import { UpdateExpertDto } from "~/libs/experts/update-expert-dto";

import ExpertOverview from "./overview";
import ExpertMedia from "./media";

export default {
  mixins: [editorForm],
  props: {
    expert: {
      type: Object,
      required: true
    },
    updating: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      photo: null,
      expertDto: { ...this.expert }
    };
  },
  watch: {
    expert() {
      this.photo = null;

      this.expertDto = { ...this.expert };
    }
  },
  methods: {
    async handleSave() {
      const photoConverted = await encodeFileToBase64(this.photo);

      this.$emit("update-expert", new UpdateExpertDto(this.expertDto, photoConverted));
    },

    changeField(payload) {
      this.commitChange(this.expertDto, payload);
    }
  },
  components: {
    ExpertOverview,
    ExpertMedia
  }
};
</script>
