<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'experts-index' }" />

    <template v-if="expert">
      <h2 class="mb-4">{{ expert.name }}</h2>

      <v-sheet elevation="1">
        <v-tabs background-color="deep-purple secondary" center-active dark hide-slider>
          <v-tab>Обзор</v-tab>

          <v-tab>Прогнозы</v-tab>

          <v-tab>Синонимы к имени и фамилии</v-tab>

          <v-tab>История мест работы</v-tab>

          <v-tab-item>
            <expert-summary :expert="expert" :updating="updating" @update-expert="updateExpert" />
          </v-tab-item>

          <v-tab-item>
            <stock-forecasts
              :analyst-id="expert.id"
              :is-show-expert="false"
              is-show-ticker
              :table-params="{ analystId: expert.id }"
            />
          </v-tab-item>

          <v-tab-item>
            <expert-synonyms :key="updatingExpertName" :expert="expert" />
          </v-tab-item>

          <v-tab-item>
            <expert-history-invest-company :key="updatingHistory" :expert="expert" />
          </v-tab-item>
        </v-tabs>
      </v-sheet>
    </template>
  </div>
</template>
<script>
import { ApiValidationError } from "~/core/api-validation-error";

import StockForecasts from "~/components/shared/stock-forecasts/table";

import ExpertSummary from "~/components/experts/id/summary";
import ExpertSynonyms from "~/components/experts/id/synonyms";
import ExpertHistoryInvestCompany from "~/components/experts/id/history-invest-company";

export default {
  data() {
    return {
      expert: null,

      loading: true,
      updating: false,
      updatingExpertName: 0,
      updatingHistory: 0
    };
  },
  created() {
    this.fetchExpert();
  },
  computed: {
    idExpert() {
      return this.$route.params.id;
    }
  },
  methods: {
    async fetchExpert() {
      this.loading = true;

      try {
        this.expert = await this.$axios.$get(`experts/${this.idExpert}`);

        this.expert.workplaceChange = false;
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не получена информация по id: "${this.idExpert}"` + html);
      } finally {
        this.loading = false;
      }
    },
    async updateExpert(payload) {
      this.updating = true;

      const firstName = this.expert?.firstName;
      const lastName = this.expert?.lastName;

      const investCompanyId = this.expert.firmId;

      try {
        this.expert = await this.$axios.$patch(`experts/${this.idExpert}`, JSON.stringify(payload), {
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (this.expert.firstName !== firstName || this.expert.lastName !== lastName) {
          this.updatingExpertName++;
        }

        if (this.expert.firmId !== investCompanyId && this.expert.workplaceChange) {
          this.updatingHistory++;
        }

        this.expert.workplaceChange = false;

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e, ["photo"]).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    }
  },
  components: {
    StockForecasts,
    ExpertSummary,
    ExpertSynonyms,
    ExpertHistoryInvestCompany
  }
};
</script>
