<template>
  <div class="pa-3">
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">Синоним</th>
          <th class="text-left">Действие</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!synonyms.length && !loading">
          <td class="text-center" colspan="2">Синонимы отсутствуют</td>
        </tr>

        <template v-else>
          <tr v-for="(synonym, index) of synonyms" :key="index">
            <td>
              <div class="d-inline-flex">
                <v-text-field v-model="synonym.firstName" class="mr-2" label="Имя" />

                <v-text-field v-model="synonym.lastName" label="Фамилия" />
              </div>
            </td>

            <td>
              <v-btn icon color="error" @click="deleteByIndex(index)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>

    <div class="mt-4 text-right">
      <v-btn color="primary mr-4" @click="add">Новый синоним</v-btn>

      <v-btn color="accent" :loading="updating" @click="save">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

export default {
  props: {
    expert: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      updating: false,
      loading: true,

      synonyms: []
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;

      try {
        const newSynonyms = await this.$axios.$get(`experts/${this.expert.id}/synonyms`);

        this.synonyms = this.formatSynonyms(newSynonyms);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не получены синонимы по id: "${this.expert.id}"` + html);
      } finally {
        this.loading = false;
      }
    },
    async save() {
      if (this.updating) return;

      this.updating = true;

      try {
        const isErrorSynonyms = this.synonyms.filter(synonym => !synonym.firstName || !synonym.lastName).length;

        if (isErrorSynonyms > 0) {
          this.$snackbar.error("Заполните пустые поля в списке");

          return;
        }

        const synonyms = this.synonyms.map(synonym => synonym.firstName.trim() + " " + synonym.lastName.trim());

        const newSynonyms = await this.$axios.$put(`experts/${this.expert.id}/synonyms`, {
          synonyms
        });

        this.synonyms = this.formatSynonyms(newSynonyms);

        this.$snackbar.success("Синонимы обновлены");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не обновлено:" + html);
      } finally {
        this.updating = false;
      }
    },

    formatSynonyms(synonyms) {
      return [...synonyms]?.map(synonym => {
        const splitSynonym = synonym.split(" ");

        return { firstName: splitSynonym.slice(0, -1).join(" "), lastName: splitSynonym[splitSynonym.length - 1] };
      });
    },

    add() {
      this.synonyms.push({ firstName: "", lastName: "" });
    },
    deleteByIndex(index) {
      this.synonyms.splice(index, 1);
    }
  }
};
</script>
