export class UpdateExpertDto {
  constructor(expert, photo = null) {
    this.photo = photo;
    this.firmId = expert.firmId;
    this.followersCount = expert.followersCount;
    this.firstName = expert.firstName;
    this.lastName = expert.lastName;
    this.expertType = expert.expertType;
    this.workplaceChange = expert.workplaceChange;
    this.isActive = expert.isActive;
    this.forRuStocks = expert.forRuStocks;
  }
}
