<template>
  <div class="pa-3">
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">Инвест-дом</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!historedPlaces.length">
          <td class="text-center" colspan="2">История отсутствует</td>
        </tr>

        <template v-else>
          <tr v-for="(place, index) of historedPlaces" :key="index">
            <td>
              {{ place.name }}
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

export default {
  props: {
    expert: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      updating: false,

      historedPlaces: []
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.historedPlaces = await this.$axios.$get(`experts/${this.expert.id}/history`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не получен список прежних мест по id: "${this.expert.id}"` + html);
      }
    },
    async save() {
      if (this.updating) return;

      this.updating = true;

      try {
        const historedPlaces = this.historedPlaces.map(place => place.name);

        this.historedPlaces = await this.$axios.$put(`experts/${this.expert.id}/historedPlaces`, {
          historedPlaces
        });

        this.$snackbar.success("История обновлена");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не обновлено:" + html);
      } finally {
        this.updating = false;
      }
    }
  }
};
</script>
